import packageJson from '../../package.json';

export const environment = {
  appVersion: packageJson.version + '-dev',
  production: false, // should be true for production
  googleTagContainerId: 'GTM-N3F2VDM',
  envName: 'dev', // should be production for production
  ondemandUser: 'NO-USER',
  javaApiUrl: 'https://dev-ctrl-api.nexxen.com/api',
  javaApiViaKongUrl: 'https://dev-ctrl-api.nexxen.com/api',
  javaInsightsUrl: 'https://dev-ctrl-api.nexxen.com/insights',
  spearadUrl: 'https://console.dev.spearad.video/login',
  spearadIframeUrl: 'https://console.dev.spearad.video',
  sentinelApi: 'https://dev-api.unruly.co/sentinel',
  exchangeDealsUrl: 'https://dev-rx-deals.nexxen.com/api',
  captcha: false, // should be true for production
  pendo: false,
  datadog: false,
  authServer: 'https://dev-api.unruly.co/realms/fusion-dev/protocol/openid-connect',
  zendeskUrl: 'https://unrulyssp-help.zendesk.com'
};
